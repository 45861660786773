import React from "react"
import { UserProfile } from "@oneauxilia/oneauxilia-react"

export default function PageProfile() {
  return (
    <>
      <UserProfile />
    </>
  )
}
